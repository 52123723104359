import '../vendor/01_bootstrap.min';
import '../vendor/02_modernizr.full.min';
import '../vendor/03_imagesloaded.pkgd.min';
// import '../vendor/04_jquery.qtip.min';
import '../vendor/05_featherlight.min';
import '../vendor/06_featherlight.gallery.min';
import '../vendor/07_jquery.mixitup.min';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import '../vendor/10_jquery.flexslider-min';
import '../vendor/11_imgsizer.min';
import $ from 'jQuery';

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.initOldScript();
    // this.initModalSupport();
    this.initTwitterBS();
    this.initFlexslider();
    this.initResizeImages();
    this.initSlideFadeToggle();
    this.initMixItUp();
    this.initQtip();
    this.watchProjectHeaders();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    // this.$doc.on(
    //   'click',
    //   'a[href*="#"]:not([href="#"])',
    //   event => this.scrollNav(event)
    // );
    // this.$win.on(
    //   'scroll',
    //   '.js-share',
    //   event => this.openShareWindow(event)
    // );
  },
  initTwitterBS() {
    function addTwitterBSClass(thisObj) {
      var title = jQuery(thisObj).attr('title');
      if (title) {
        var titles = title.split(' ');
        if (titles[0]) {
          var num = parseInt(titles[0]);
          if (num > 0)
            jQuery(thisObj).addClass('label label-default');
          if (num == 2)
            jQuery(thisObj).addClass('label label-info');
          if (num > 2 && num < 4)
            jQuery(thisObj).addClass('label label-success');
          if (num >= 5 && num < 10)
            jQuery(thisObj).addClass('label label-warning');
          if (num >=10)
            jQuery(thisObj).addClass('label label-important');
        }
      }
      else
        jQuery(thisObj).addClass('label');
      return true;
    }
  },

  initOldScript() {

    // modify tag cloud links to match up with twitter bootstrap
    $("#tag-cloud a").each(function() {
        addTwitterBSClass(this);
        return true;
    });

    $("p.tags a").each(function() {
      addTwitterBSClass(this);
      return true;
    });

    $("ol.commentlist a.comment-reply-link").each(function() {
      $(this).addClass('btn btn-success btn-mini');
      return true;
    });

    $('#cancel-comment-reply-link').each(function() {
      $(this).addClass('btn btn-danger btn-mini');
      return true;
    });

    $('article.post').hover(function(){
      $('a.edit-post').show();
    },function(){
      $('a.edit-post').hide();
    });


    // Prevent submission of empty form
    $('[placeholder]').parents('form').submit(function() {
      $(this).find('[placeholder]').each(function() {
        var input = $(this);
        if (input.val() == input.attr('placeholder')) {
          input.val('');
        }
      })
    });

    $('.alert-message').alert();

    $('.dropdown-toggle').dropdown();

    $('#tabs a').click(function (e) {
      if($(this).parent('li').hasClass('active')){
          $( $(this).attr('href') ).hide();
      }
      else {
          e.preventDefault();
          $(this).tab('show');
      }
    });
  },

  initFlexslider() {
    // FlexSlider v2.0 // products
    $('.flexslider-thumbs').flexslider({
      animation: "slider",
      controlNav: "thumbnails",
      animationLoop: false,
      slideshow: false,
    });

    // FlexSlider v2.0
    $('.flexslider').flexslider({
      animation: "fade",
      controlNav: true,
    });
  },

  initMixItUp() {
    // MixitUp
    const { watchProjectHeaders } = this;
    $('#mix-container').mixItUp({
      animation: {
        duration: 700,
        effects: 'fade scale(1.00)',
        easing: 'ease'
      },
      callbacks: {
        onMixEnd: function() {
          watchProjectHeaders()
        }
      }
    });
    var filter = location.hash.replace("#","\.");
    if (location.hash) {
      jQuery('#mix-container').mixItUp('filter', filter);
    }
  },

  initResizeImages() {
    // resize product's image and delay on load this page
    function resizeImage() {
      var prodImg = $('li.product__image img');
      prodImg.css('max-height', ($('.product__info--text').height())*1.5);
      prodImg.delay(100).fadeIn(800);
    }

    resizeImage();

    $('.single-remodal-project .inner-content').on('mouseenter', function(event) {
      event.stopPropagation();
      if ($(window).width() > 1023) {
        $(event.currentTarget).find('.before-image-thumb').fadeOut(300, function() {
          $(event.currentTarget).find('.before-image-thumb').css('visibility', 'visible')
        }).fadeIn(300);
      } else {
        $(event.currentTarget).find('.before-image-thumb').css('visibility', 'visible')
      }
    });

    $('.single-remodal-project .inner-content').on('mouseleave', function(event) {
      event.stopPropagation();
      if ($(window).width() > 1023) {
      $(event.currentTarget).find('.before-image-thumb').fadeOut(300, function() {
            $(event.currentTarget).find('.before-image-thumb').css({'visibility': 'hidden', 'display': 'block'})
        });
      } else {
        $(event.currentTarget).find('.before-image-thumb').css('visibility', 'visible')
      }
    });

    $(window).resize(function() {
      if ($(window).width() <= 1023) {
        $('.before-image-thumb').css('visibility', 'visible')
       } else {
        $('.before-image-thumb').css('visibility', 'hidden')
       }
    });
  },

  initSlideFadeToggle() {
    $.fn.slideFadeToggle  = function(speed, easing, callback) {
            return this.animate({opacity: 'toggle', height: 'toggle'}, speed, easing, callback);
    };
    $('.team-content').on('click', '.readmore', function() {
        var readmore = $(this).text() == 'Read More' ? 'Read Less' : 'Read More';
        $(this).html(readmore);

        $('.team-content').find('p:nth-of-type(n+4)').each(function() {
            $(this).slideFadeToggle( "fast" );
        });
    });
  },

  initQtip() {
    // qTip
    $('.project').each(function() {
      $(this).qtip({
        content: {
            text: $(this).next('div')
        },
        position: {
          my: 'bottom left',
          at: 'top left',
          viewport: $('.inner-container'),
          adjust: {
            method: 'shift none',
            y: 10
          }
        },
        hide: {
          fixed: true
        }
      });
    });
  },
  watchProjectHeaders() {
    if ( $('.portfolio__header').length ) {
      $('.portfolio__header').each(function() {
        if ($(this).nextUntil('.portfolio__header').is(':visible')) {
          $(this).show()
        } else {
          $(this).hide()
        }
      })
    }
  }
};

Mangrove.init();
